import { of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/circle.service";
import * as i2 from "@angular/router";
var CircleGuard = /** @class */ (function () {
    function CircleGuard(circle, router) {
        this.circle = circle;
        this.router = router;
    }
    CircleGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (!next.params.token) {
            return of(false);
        }
        return this.circle.getCircle(next.params.token).pipe(switchMap(function (response) { return of(true); }), catchError(function (error) {
            _this.router.navigate(['cercles']);
            return of(false);
        }));
    };
    CircleGuard.prototype.canActivateChild = function (next, state) {
        var _this = this;
        if (!next.data.adminAccess) {
            return of(true);
        }
        return this.circle.getCircleMembersMe(next.parent.params.token).pipe(switchMap(function (member) { return member.isAdmin ? of(true) : throwError('Not admin'); }), catchError(function (error) {
            _this.router.navigate(['cercles', next.parent.params.token]);
            return of(false);
        }));
    };
    CircleGuard.prototype.canLoad = function (route, segments) {
        return true;
    };
    CircleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CircleGuard_Factory() { return new CircleGuard(i0.ɵɵinject(i1.CircleService), i0.ɵɵinject(i2.Router)); }, token: CircleGuard, providedIn: "root" });
    return CircleGuard;
}());
export { CircleGuard };
