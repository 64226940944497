import { HttpHeaders } from '@angular/common/http';
import { Post } from '../models/post.model';
import { Comment } from '../models/comment.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Reaction } from '../models/reaction.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./circle.service";
import * as i3 from "./api.service";
import * as i4 from "@angular/common/http";
var PostService = /** @class */ (function () {
    function PostService(feathers, circleService, apiService, http) {
        this.feathers = feathers;
        this.circleService = circleService;
        this.apiService = apiService;
        this.http = http;
        this._fsServiceName = "posts";
        this._createdPost = new BehaviorSubject(null);
        this._updatedPost = new BehaviorSubject(null);
        this.setCreatedPostEvent();
        this.setUpdatedPostEvent();
    }
    PostService.prototype.onCreatedPost = function () {
        return this._createdPost.asObservable();
    };
    PostService.prototype.onUpdatedPost = function () {
        return this._updatedPost.asObservable();
    };
    PostService.prototype.createPost = function (data) {
        var accessToken = this.feathers.client.settings.accessToken;
        var httpOptions = {
            headers: new HttpHeaders({
                'authorization': accessToken
            })
        };
        return this.http.post(environment.fsAPI.url + '/' + this._fsServiceName, data, httpOptions).pipe(map(function (post) {
            return new Post().deserialize(post);
        }));
    };
    PostService.prototype.updatePost = function (data) {
        var accessToken = this.feathers.client.settings.accessToken;
        var httpOptions = {
            headers: new HttpHeaders({
                'authorization': accessToken
            })
        };
        return this.http.put(environment.fsAPI.url + '/' + this._fsServiceName + '/' + data.get('_id'), data, httpOptions).pipe(map(function (post) {
            return new Post().deserialize(post);
        }));
    };
    PostService.prototype.getPost = function (token) {
        var accessToken = this.feathers.client.settings.accessToken;
        var httpOptions = {
            headers: new HttpHeaders({
                'authorization': accessToken
            })
        };
        return this.http.get(environment.fsAPI.url + '/' + this._fsServiceName + '/' + token, httpOptions).pipe(map(function (post) {
            return new Post().deserialize(post);
        }));
    };
    PostService.prototype.deletePost = function (token) {
        return this.apiService.delete(this._fsServiceName, token).pipe(map(function (post) {
            return new Post().deserialize(post);
        }));
    };
    PostService.prototype.setCreatedPostEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('created', function (post) {
            _this._createdPost.next(new Post().deserialize(post));
        });
    };
    PostService.prototype.setUpdatedPostEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('updated', function (post) {
            _this._updatedPost.next(new Post().deserialize(post));
        });
    };
    PostService.prototype.getPostComments = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/comments'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (comments) {
            comments.forEach(function (value, index) {
                comments[index] = new Comment().deserialize(value);
            });
            return comments;
        }));
    };
    PostService.prototype.getPostDeletedComments = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/deleted-comments'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams);
    };
    PostService.prototype.getPostReactions = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/reactions'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (reactions) {
            reactions.forEach(function (value, index) {
                reactions[index] = new Reaction().deserialize(value);
            });
            return reactions;
        }));
    };
    PostService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostService_Factory() { return new PostService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.CircleService), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i4.HttpClient)); }, token: PostService, providedIn: "root" });
    return PostService;
}());
export { PostService };
