import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { switchMap, map, finalize } from 'rxjs/operators';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { Avatar } from '../models/avatar.model';
import { CookieStorage } from 'cookie-storage';
import { SubscriptionModel } from '../models/subscription.model';
import { Invoice } from '../models/invoice.model';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./feathers.service";
import * as i3 from "./api.service";
import * as i4 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(authService, feathersService, apiService, http) {
        this.authService = authService;
        this.feathersService = feathersService;
        this.apiService = apiService;
        this.http = http;
        this._fsServiceName = 'users';
        this._updatedUser = new BehaviorSubject(null);
        this._storageKey = 'at';
        this._cookieStorage = new CookieStorage();
    }
    UserService.prototype.createUser = function (data) {
        var _this = this;
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            return _this.apiService.post(_this._fsServiceName, data);
        }), map(function (user) { return new User().deserialize(user); }), finalize(function () { return _this.authService.logout(); }));
    };
    UserService.prototype.updateUser = function (token, user) {
        return this.apiService.put(this._fsServiceName, token, user.serialize()).pipe(map(function (user) {
            return new User().deserialize(user);
        }));
    };
    UserService.prototype.onUpdatedUser = function () {
        return this._updatedUser;
    };
    UserService.prototype.setUpdatedUserEvent = function () {
        var _this = this;
        this.feathersService.client.service(this._fsServiceName).on('updated', function (comment) {
            _this._updatedUser.next(new User().deserialize(comment));
            _this._updatedUser.next(null);
        });
    };
    UserService.prototype.getUser = function (token) {
        return this.apiService.get(this._fsServiceName, token).pipe(map(function (user) { return new User().deserialize(user); }));
    };
    UserService.prototype.updateAvatar = function (token, data) {
        var accessToken = this.feathersService.client.settings.accessToken;
        var httpOptions = {
            headers: new HttpHeaders({
                authorization: accessToken
            }),
            params: new HttpParams().set('operationPath', "/" + token + "/avatar")
        };
        return this.http.post(environment.fsAPI.url + '/' + this._fsServiceName, data, httpOptions).pipe(map(function (avatar) {
            return new Avatar().deserialize(avatar);
        }));
    };
    UserService.prototype.quitApplication = function (id, data) {
        var params = {
            query: {
                operationPath: '/quit-app'
            }
        };
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(function (user) { return new User().deserialize(user); }));
    };
    UserService.prototype.downloadArchive = function (token, hash, archiveId, expires, memberId, code) {
        var _this = this;
        this._cookieStorage.removeItem(this._storageKey);
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            var defaultParams = {
                query: {
                    operationPath: "/archive",
                    _hash: hash,
                    code: code,
                    archive: archiveId,
                    expire: expires,
                    membre: memberId
                }
            };
            return _this.apiService.get(_this._fsServiceName, token, defaultParams);
        }), finalize(function () { return _this.authService.logout(); }));
    };
    UserService.prototype.regenerateArchive = function (id) {
        var _this = this;
        var params = {
            query: {
                operationPath: '/regenerate-archive'
            }
        };
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            return _this.apiService.get(_this._fsServiceName, id, params);
        }), finalize(function () { return _this.authService.logout(); }));
    };
    UserService.prototype.getUserSubscriptions = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/subscriptions'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (subscriptions) {
            subscriptions.forEach(function (value, index) {
                subscriptions[index] = new SubscriptionModel().deserialize(value);
            });
            return subscriptions;
        }));
    };
    UserService.prototype.getUserInvoices = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/invoices'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (invoices) {
            invoices.forEach(function (value, index) {
                invoices[index] = new Invoice().deserialize(value);
            });
            return invoices;
        }));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.FeathersService), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i4.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
