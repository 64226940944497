import { Member } from '../models/member.model';
import { BehaviorSubject, from } from 'rxjs';
import { map, switchMap, finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
import * as i3 from "./auth.service";
var MemberService = /** @class */ (function () {
    function MemberService(apiService, feathersService, authService) {
        this.apiService = apiService;
        this.feathersService = feathersService;
        this.authService = authService;
        this._fsServiceName = "members";
        this._createdMember = new BehaviorSubject(null);
        this._updatedMember = new BehaviorSubject(null);
        this._patchedMember = new BehaviorSubject(null);
        this.setCreatedMemberEvent();
        this.setUpdatedMemberEvent();
        this.setPatchedMemberEvent();
    }
    MemberService.prototype.onCreatedMember = function () {
        return this._createdMember.asObservable();
    };
    MemberService.prototype.onUpdatedMember = function () {
        return this._updatedMember.asObservable();
    };
    MemberService.prototype.onPatchedMember = function () {
        return this._patchedMember.asObservable();
    };
    MemberService.prototype.createMember = function (data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (member) { return new Member().deserialize(member); }));
    };
    MemberService.prototype.leaveCircleMember = function (id, data) {
        var params = {
            query: {
                operationPath: '/leave'
            }
        };
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(function (member) { return new Member().deserialize(member); }));
    };
    MemberService.prototype.patchMember = function (id, data, params) {
        if (params === void 0) { params = {}; }
        return this.apiService.patch(this._fsServiceName, data, params).pipe(map(function (member) { return new Member().deserialize(member); }));
    };
    MemberService.prototype.deleteMember = function (id) {
        return this.apiService.delete(this._fsServiceName, id);
    };
    MemberService.prototype.regenerateArchive = function (id) {
        var _this = this;
        var params = {
            query: {
                operationPath: '/regenerate-archive'
            }
        };
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            return _this.apiService.get(_this._fsServiceName, id, params).pipe(map(function (member) { return new Member().deserialize(member); }));
        }), finalize(function () { return _this.authService.logout(); }));
    };
    MemberService.prototype.setCreatedMemberEvent = function () {
        var _this = this;
        this.feathersService.client.service(this._fsServiceName).on('created', function (member) {
            _this._createdMember.next(new Member().deserialize(member));
        });
    };
    MemberService.prototype.setUpdatedMemberEvent = function () {
        var _this = this;
        this.feathersService.client.service(this._fsServiceName).on('updated', function (member) {
            _this._updatedMember.next(new Member().deserialize(member));
        });
    };
    MemberService.prototype.setPatchedMemberEvent = function () {
        var _this = this;
        this.feathersService.client.service(this._fsServiceName).on('patched', function (member) {
            _this._patchedMember.next(new Member().deserialize(member));
        });
    };
    MemberService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MemberService_Factory() { return new MemberService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService), i0.ɵɵinject(i3.AuthService)); }, token: MemberService, providedIn: "root" });
    return MemberService;
}());
export { MemberService };
