import { Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { SubscriptionResultComponent } from './subscription-result/subscription-result.component';
import { SubscriptionUpdateComponent } from './subscription-update/subscription-update.component';
import { SubscriptionUpdateResultComponent } from './subscription-update-result/subscription-update-result.component';
import { SubscriptionIncreaseStorageComponent } from './subscription-increase-storage/subscription-increase-storage.component';
import { SubscriptionInvoicesComponent } from './subscription-invoices/subscription-invoices.component';
import { AccountGuard } from '../core/guards/account.guard';
var ɵ0 = {
    hideHeader: true,
    hideFooter: true
}, ɵ1 = {
    hideHeader: true,
    hideFooter: true
}, ɵ2 = {
    hideHeader: true,
    hideFooter: true
}, ɵ3 = {
    hideHeader: true,
    hideFooter: true
};
var routes = [
    {
        path: "modifier",
        component: EditProfileComponent,
    },
    {
        path: "abonnement",
        component: SubscriptionComponent
    },
    {
        path: "abonnement/mettre-a-jour",
        component: SubscriptionComponent,
    },
    {
        path: "abonnement/augmenter-stockage",
        component: SubscriptionIncreaseStorageComponent,
    },
    {
        path: "abonnement/factures",
        component: SubscriptionInvoicesComponent,
    },
    {
        path: "paiement",
        component: SubscriptionPaymentComponent,
        data: ɵ0
    },
    {
        path: "resultat",
        component: SubscriptionResultComponent,
        data: ɵ1
    },
    {
        path: "abonnement/mettre-a-jour/iframe",
        component: SubscriptionUpdateComponent,
        data: ɵ2
    },
    {
        path: "abonnement/mettre-a-jour/resultat",
        component: SubscriptionUpdateResultComponent,
        data: ɵ3
    },
    {
        path: ":token",
        component: ProfileComponent,
        canActivate: [AccountGuard],
        runGuardsAndResolvers: 'always'
    },
];
var AccountRoutingModule = /** @class */ (function () {
    function AccountRoutingModule() {
    }
    return AccountRoutingModule;
}());
export { AccountRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
