import { Circle } from '../models/circle.model';
import { Post } from '../models/post.model';
import { Member } from '../models/member.model';
import { Event } from '../models/event.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment } from '../models/appointment.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
var CircleService = /** @class */ (function () {
    function CircleService(apiService, feathers) {
        this.apiService = apiService;
        this.feathers = feathers;
        this._fsServiceName = "circles";
        this._currentCircle = new BehaviorSubject(null);
        this._updatedCircle = new BehaviorSubject(null);
        this.setUpdatedCircleEvent();
    }
    CircleService.prototype.getCurrentCircle = function () {
        return this._currentCircle.asObservable();
    };
    CircleService.prototype.updateCurrentCircle = function (circle) {
        this._currentCircle.next(circle);
    };
    CircleService.prototype.createCircle = function (data, params) {
        if (params === void 0) { params = {}; }
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (circle) { return new Circle().deserialize(circle); }));
    };
    CircleService.prototype.updateCircle = function (token, data) {
        return this.apiService.put(this._fsServiceName, token, data).pipe(map(function (circle) {
            return new Circle().deserialize(circle);
        }));
    };
    CircleService.prototype.getCircles = function (params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: params
        };
        return this.apiService.getAll(this._fsServiceName, defaultParams).pipe(map(function (circles) {
            circles.forEach(function (value, index) {
                circles[index] = new Circle().deserialize(value);
            });
            return circles;
        }));
    };
    CircleService.prototype.getCircle = function (token) {
        return this.apiService.get(this._fsServiceName, token).pipe(map(function (circle) { return new Circle().deserialize(circle); }));
    };
    CircleService.prototype.getCirclePosts = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/posts'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (posts) {
            posts.forEach(function (value, index) {
                posts[index] = new Post().deserialize(value);
            });
            return posts;
        }));
    };
    CircleService.prototype.getCircleMembers = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/members'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (members) {
            members.forEach(function (value, index) {
                members[index] = new Member().deserialize(value);
            });
            return members;
        }));
    };
    CircleService.prototype.getCircleEvents = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/events'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (events) {
            events.forEach(function (value, index) {
                events[index] = new Event().deserialize(value);
            });
            return events;
        }));
    };
    CircleService.prototype.getCircleMembersMe = function (token) {
        var params = {
            query: {
                operationPath: '/members/me'
            }
        };
        return this.apiService.get(this._fsServiceName, token, params).pipe(map(function (member) { return new Member().deserialize(member); }));
    };
    CircleService.prototype.onUpdatedCircle = function () {
        return this._updatedCircle;
    };
    CircleService.prototype.deleteCircle = function (id, data) {
        var params = {
            query: {
                operationPath: '/delete'
            }
        };
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(function (circle) { return new Circle().deserialize(circle); }));
    };
    CircleService.prototype.setUpdatedCircleEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('updated', function (circle) {
            _this._updatedCircle.next(new Circle().deserialize(circle));
            _this._updatedCircle.next(null);
        });
    };
    CircleService.prototype.getCircleAppointments = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/appointments'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (appointments) {
            appointments.forEach(function (value, index) {
                appointments[index] = new Appointment().deserialize(value);
            });
            return appointments;
        }));
    };
    CircleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CircleService_Factory() { return new CircleService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService)); }, token: CircleService, providedIn: "root" });
    return CircleService;
}());
export { CircleService };
