import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Proposal } from '../models/proposal.model';
import { Vote } from '../models/vote.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
var ProposalService = /** @class */ (function () {
    function ProposalService(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "proposals";
        this._createdProposal = new BehaviorSubject(null);
        this.setCreatedProposalEvent();
    }
    ProposalService.prototype.onCreatedProposal = function () {
        return this._createdProposal;
    };
    ProposalService.prototype.createProposal = function (data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (event) { return new Proposal().deserialize(event); }));
    };
    ProposalService.prototype.setCreatedProposalEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('created', function (event) {
            _this._createdProposal.next(new Proposal().deserialize(event));
            _this._createdProposal.next(null);
        });
    };
    ProposalService.prototype.getProposalVotes = function (token, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/votes'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, token, defaultParams).pipe(map(function (votes) {
            votes.forEach(function (value, index) {
                votes[index] = new Vote().deserialize(value);
            });
            return votes;
        }));
    };
    ProposalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProposalService_Factory() { return new ProposalService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: ProposalService, providedIn: "root" });
    return ProposalService;
}());
export { ProposalService };
