import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import * as i0 from "@angular/core";
var MeService = /** @class */ (function () {
    function MeService() {
        this._storageKey = 'me';
        this._updatedMe = new BehaviorSubject(null);
        this._localStorage = window.localStorage;
    }
    MeService.prototype.onUpdatedMe = function () {
        return this._updatedMe.asObservable();
    };
    MeService.prototype.storeMe = function (user) {
        this._localStorage.setItem(this._storageKey, JSON.stringify(user));
        this._updatedMe.next(user);
    };
    MeService.prototype.getMe = function () {
        if (this._localStorage.getItem(this._storageKey)) {
            return new User().deserialize(JSON.parse(this._localStorage.getItem(this._storageKey)));
        }
        return null;
    };
    MeService.prototype.deleteMe = function () {
        this._localStorage.removeItem(this._storageKey);
        this._updatedMe.next(null);
    };
    MeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeService_Factory() { return new MeService(); }, token: MeService, providedIn: "root" });
    return MeService;
}());
export { MeService };
