import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./me.service";
import * as i2 from "./post.service";
import * as i3 from "./comment.service";
import * as i4 from "./circle.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(meService, postService, commentService, circleService) {
        this.meService = meService;
        this.postService = postService;
        this.commentService = commentService;
        this.circleService = circleService;
        this._createdNotification = new BehaviorSubject(null);
        this._observers = [];
        this.setObservers();
    }
    NotificationService.prototype.setObservers = function () {
        this._observers.push(this.onCreatedPost());
        this._observers.push(this.onCreatedComment());
    };
    NotificationService.prototype.onCreatedNotification = function () {
        return this._createdNotification.asObservable();
    };
    NotificationService.prototype.onCreatedPost = function () {
        var _this = this;
        return this.postService.onCreatedPost().subscribe(function (post) {
            var me = _this.meService.getMe();
            if (post) {
                _this.circleService.getCurrentCircle().subscribe(function (circle) {
                    if (post.member.user.getId() != me.getId() && (!circle || post.circle.getIRI() != circle.getIRI())) {
                        _this._createdNotification.next({
                            title: 'Nouveau post',
                            description: post.member.user.firstName + " " + post.member.user.lastName + " vient de publier un post sur " + post.circle.name
                        });
                    }
                });
            }
        });
    };
    NotificationService.prototype.onCreatedComment = function () {
        var _this = this;
        return this.commentService.onCreatedComment().subscribe(function (comment) {
            var me = _this.meService.getMe();
            if (comment) {
                _this.circleService.getCurrentCircle().subscribe(function (circle) {
                    if (comment.member.user.getId() != me.getId() && (!circle || comment.post.circle.getIRI() != circle.getIRI())) {
                        _this._createdNotification.next({
                            title: 'Nouveau commentaire',
                            description: comment.member.user.firstName + " " + comment.member.user.lastName + " vient d'ajouter un commmentaire sur " + comment.post.circle.name
                        });
                    }
                });
            }
        });
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.MeService), i0.ɵɵinject(i2.PostService), i0.ɵɵinject(i3.CommentService), i0.ɵɵinject(i4.CircleService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
