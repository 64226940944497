import { Observable, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
var InvitationVisioService = /** @class */ (function () {
    function InvitationVisioService(apiService, feathersService) {
        this.apiService = apiService;
        this.feathersService = feathersService;
        this._fsServiceName = "invitationsVisio";
        this._createdInvitation = new Subject();
        this.setCreatedInvitationEvent();
    }
    InvitationVisioService.prototype.createInvitation = function (data) {
        return this.apiService.post(this._fsServiceName, data);
    };
    InvitationVisioService.prototype.onCreatedInvitation = function () {
        return this._createdInvitation;
    };
    InvitationVisioService.prototype.setCreatedInvitationEvent = function () {
        var _this = this;
        this.feathersService.client.service(this._fsServiceName).on('created', function (invitation) {
            _this._createdInvitation.next(invitation);
        });
    };
    InvitationVisioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationVisioService_Factory() { return new InvitationVisioService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService)); }, token: InvitationVisioService, providedIn: "root" });
    return InvitationVisioService;
}());
export { InvitationVisioService };
