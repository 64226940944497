import { OnInit, ChangeDetectorRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReactionType, reactionTypeFilled, reactionTypeOutlined } from '../../../core/enums/reaction-type.enum';
import { Member } from '../../../core/models/member.model';
import { Comment } from '../../../core/models/comment.model';
import { ModalReactionsComponent } from '../modal-reactions/modal-reactions.component';
import { ModalEditCommentComponent } from '../modal-edit-comment/modal-edit-comment.component';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
var CommentComponent = /** @class */ (function () {
    function CommentComponent(reactionService, commentService, toastrService, modalService, loadingService, alertService, datePipe, changeDetection) {
        this.reactionService = reactionService;
        this.commentService = commentService;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.loadingService = loadingService;
        this.alertService = alertService;
        this.datePipe = datePipe;
        this.changeDetection = changeDetection;
        this.comment = new Comment();
        this.memberMe = new Member();
        this._observers = [];
        this.subscriptionsModal = [];
        this.newReaction = {};
        this.reactions = [];
        this.newReactions = [];
        this.reactionOutlined = reactionTypeOutlined;
        this.reactionFilled = reactionTypeFilled;
        this.reactionMemberMe = "";
        this.nbReactions = {};
        this.commentToDelete = {};
        this.isReacted = false;
        this.orderOriginal = function (a, b) {
            return 0;
        };
    }
    CommentComponent.prototype.ngOnInit = function () {
        this.setObservers();
        this.getReactions();
    };
    CommentComponent.prototype.setObservers = function () {
        this._observers.push(this.setCreatedReactionObserver());
        this._observers.push(this.setUpdatedReactionObserver());
        this._observers.push(this.setRemovedReactionObserver());
    };
    CommentComponent.prototype.setReactionMemberMe = function () {
        for (var i = 0; i < this.reactions.length; i++) {
            var reaction = this.reactions[i];
            if (reaction.member.getIRI() == this.memberMe.getIRI()) {
                this.reactionMemberMe = reaction.type;
                break;
            }
        }
    };
    CommentComponent.prototype.getClassReactionMemberMe = function () {
        return this.reactionFilled[this.reactionMemberMe] ? this.reactionFilled[this.reactionMemberMe] : "far fa-thumbs-up";
    };
    CommentComponent.prototype.getReactions = function () {
        var _this = this;
        this.commentService.getCommentReactions(this.comment.getId()).subscribe(function (reactions) {
            reactions = reactions.filter(function (reaction) {
                return !_this.newReactions.map(function (newReaction) {
                    return newReaction.getId();
                }).includes(reaction.getId());
            });
            _this.reactions = _this.reactions.concat(reactions);
            _this.getNbReactionsByType();
            _this.setReactionMemberMe();
        });
    };
    CommentComponent.prototype.setCreatedReactionObserver = function () {
        var _this = this;
        return this.reactionService.onCreatedReaction()
            .subscribe(function (reaction) {
            if (!reaction) {
                return;
            }
            if (reaction.comment != null) {
                if (_this.comment.getIRI() == reaction.comment.getIRI()) {
                    _this.newReactions.push(reaction);
                    _this.getNbReactionsByType();
                    if (_this.memberMe.getId() != reaction.member.getId()) {
                        _this.toastrService.success(reaction.member.user.firstName + " " + reaction.member.user.lastName + " vient de r\u00E9agir \u00E0 un commentaire", 'Notification');
                    }
                }
            }
        });
    };
    CommentComponent.prototype.setUpdatedReactionObserver = function () {
        var _this = this;
        return this.reactionService.onUpdatedReaction()
            .subscribe(function (reaction) {
            if (!reaction) {
                return;
            }
            if (reaction.comment != null) {
                if (_this.comment.getIRI() == reaction.comment.getIRI()) {
                    _this.reactions = _this.mapReactions(_this.reactions, reaction);
                    _this.newReactions = _this.mapReactions(_this.newReactions, reaction);
                    _this.getNbReactionsByType();
                }
            }
        });
    };
    CommentComponent.prototype.setRemovedReactionObserver = function () {
        var _this = this;
        return this.reactionService.onRemovedReaction()
            .subscribe(function (reaction) {
            if (!reaction) {
                return;
            }
            _this.newReactions = _this.filterReactions(_this.newReactions, reaction);
            _this.reactions = _this.filterReactions(_this.reactions, reaction);
            _this.getNbReactionsByType();
        });
    };
    CommentComponent.prototype.getNbReactionsByType = function () {
        var _a;
        this.nbReactions = (_a = {}, _a[ReactionType.LIKE] = 0, _a[ReactionType.LOVE] = 0, _a[ReactionType.QUESTION] = 0, _a);
        for (var i = 0; i < this.reactions.length; i++) {
            this.nbReactions[this.reactions[i].type] += 1;
        }
        for (var j = 0; j < this.newReactions.length; j++) {
            this.nbReactions[this.newReactions[j].type] += 1;
        }
        this.isReacted = this.reactions.length > 0 || this.newReactions.length > 0;
    };
    CommentComponent.prototype.onLike = function () {
        this.react(ReactionType.LIKE);
    };
    CommentComponent.prototype.onLove = function () {
        this.react(ReactionType.LOVE);
    };
    CommentComponent.prototype.onQuestion = function () {
        this.react(ReactionType.QUESTION);
    };
    CommentComponent.prototype.react = function (reactionType) {
        var _this = this;
        var alreadyReacted = false;
        var currentReaction = {};
        for (var i = 0; i < this.reactions.length; i++) {
            if (this.reactions[i].member.getIRI() == this.memberMe.getIRI()) {
                currentReaction = this.reactions[i];
                alreadyReacted = true;
                break;
            }
        }
        for (var j = 0; j < this.newReactions.length; j++) {
            if (this.newReactions[j].member.getIRI() == this.memberMe.getIRI()) {
                currentReaction = this.newReactions[j];
                alreadyReacted = true;
                break;
            }
        }
        if (alreadyReacted) {
            if (currentReaction.type != reactionType) {
                currentReaction.type = reactionType;
                currentReaction.comment = this.comment.getIRI();
                this.reactionService.updateReaction(currentReaction.token, currentReaction)
                    .subscribe(function (reaction) {
                    _this.reactionMemberMe = reactionType;
                });
            }
            else {
                this.reactionService.deleteReaction(currentReaction, currentReaction.token)
                    .subscribe(function (reaction) {
                    _this.reactionMemberMe = "";
                });
                this.reactionService.patchReaction(currentReaction).subscribe(function (reaction) {
                });
            }
        }
        else {
            this.newReaction.comment = this.comment.getIRI();
            this.newReaction.type = reactionType;
            this.reactionService.createReaction(this.newReaction)
                .subscribe(function (reaction) {
                _this.reactionMemberMe = reactionType;
            });
        }
    };
    CommentComponent.prototype.filterReactions = function (reactionsTab, deletedReaction) {
        for (var i = 0; i < reactionsTab.length; i++) {
            if (deletedReaction && reactionsTab[i].getIRI() == deletedReaction.getIRI()) {
                reactionsTab.splice(i, 1);
            }
        }
        return reactionsTab;
    };
    CommentComponent.prototype.mapReactions = function (reactionsTab, currentReaction) {
        for (var i = 0; i < reactionsTab.length; i++) {
            if (reactionsTab[i].getIRI() == currentReaction.getIRI()) {
                reactionsTab[i] = currentReaction;
            }
        }
        return reactionsTab;
    };
    CommentComponent.prototype.openReactionModal = function () {
        this.modalReaction = this.modalService.show(ModalReactionsComponent);
        this.modalReaction.content.nbReactions = this.nbReactions;
        this.modalReaction.content.reactions = this.reactions.concat(this.newReactions);
    };
    CommentComponent.prototype.onEdit = function () {
        var initialState = {
            comment: this.comment
        };
        this.modalEditComment = this.modalService.show(ModalEditCommentComponent, { initialState: initialState });
    };
    CommentComponent.prototype.setDeletedComment = function () {
        this.commentToDelete.post = this.comment.post.getIRI();
        this.commentToDelete.deletedAt = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    };
    CommentComponent.prototype.onDelete = function () {
        var _this = this;
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = "supprimer ce commentaire";
        var _combine = combineLatest(this.modalService.onHide).subscribe(function () { return _this.changeDetection.markForCheck(); });
        this.subscriptionsModal.push(this.modalService.onHide.subscribe(function (reason) {
            if (_this.modalConfirmation.content && _this.modalConfirmation.content.confirmation) {
                _this.setDeletedComment();
                _this.commentService.updateComment(_this.comment.getId(), _this.commentToDelete).pipe(finalize(function () { return _this.loadingService.dismissLoading(); })).subscribe(function (comment) {
                    if (!comment) {
                        return;
                    }
                    if (_this.comment.getIRI() == comment.getIRI()) {
                        _this.alertService.closeAlert();
                        _this.toastrService.success('Votre commentaire a bien été supprimé', 'Confirmation');
                        _this.modalConfirmation = null;
                    }
                }, function (error) {
                    _this.alertService.showAlert(error.error.errors, 'danger');
                });
            }
            _this.unsubscribe();
        }));
        this.subscriptionsModal.push(_combine);
    };
    CommentComponent.prototype.unsubscribe = function () {
        this.subscriptionsModal.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    };
    CommentComponent.prototype.ngOnDestroy = function () {
        this._observers.forEach(function (observer, index) {
            observer.unsubscribe();
        });
    };
    return CommentComponent;
}());
export { CommentComponent };
