import { PipeTransform } from '@angular/core';
var ShortenPipe = /** @class */ (function () {
    function ShortenPipe() {
    }
    ShortenPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var length = args[0] || 15;
        var splittedName = value.split(".");
        var extension = splittedName.length > 1 ? splittedName[splittedName.length - 1] : ' ';
        var name = value.substr(0, value.length - extension.length - 1);
        if (name.length > length) {
            return extension === ' ' ? value.substr(0, length) + '...' : value.substr(0, length) + '...' + extension;
        }
        return value;
    };
    return ShortenPipe;
}());
export { ShortenPipe };
