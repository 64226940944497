import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/invitation.service";
import * as i2 from "@angular/router";
var InvitationGuard = /** @class */ (function () {
    function InvitationGuard(invitationService, router) {
        this.invitationService = invitationService;
        this.router = router;
    }
    InvitationGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (!next.params.token) {
            return of(false);
        }
        return this.invitationService.getInvitation(next.params.token).pipe(switchMap(function (response) { return of(true); }), catchError(function (error) {
            _this.router.navigate(['']);
            return of(false);
        }));
    };
    InvitationGuard.prototype.canActivateChild = function (next, state) {
        return true;
    };
    InvitationGuard.prototype.canLoad = function (route, segments) {
        return true;
    };
    InvitationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationGuard_Factory() { return new InvitationGuard(i0.ɵɵinject(i1.InvitationService), i0.ɵɵinject(i2.Router)); }, token: InvitationGuard, providedIn: "root" });
    return InvitationGuard;
}());
export { InvitationGuard };
