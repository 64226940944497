import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Post } from '../../../core/models/post.model';
import { Member } from '../../../core/models/member.model';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactionType, reactionTypeFilled, reactionTypeOutlined } from '../../../core/enums/reaction-type.enum';
import { ModalReactionsComponent } from '../modal-reactions/modal-reactions.component';
import { ModalEditPostComponent } from '../modal-edit-post/modal-edit-post.component';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
var PostComponent = /** @class */ (function () {
    function PostComponent(commentService, loadingService, toastrService, postService, alertService, reactionService, modalService, datePipe, changeDetection) {
        this.commentService = commentService;
        this.loadingService = loadingService;
        this.toastrService = toastrService;
        this.postService = postService;
        this.alertService = alertService;
        this.reactionService = reactionService;
        this.modalService = modalService;
        this.datePipe = datePipe;
        this.changeDetection = changeDetection;
        this.post = new Post();
        this.memberMe = new Member();
        this.openedComments = false;
        this._observers = [];
        this.subscriptionsModal = [];
        this._commentsPage = 1;
        this.newComments = [];
        this.loadedComments = [];
        this.nbDeletedComments = 0;
        this.newComment = {};
        this.newReaction = {};
        this.reactions = [];
        this.newReactions = [];
        this.reactionOutlined = reactionTypeOutlined;
        this.reactionFilled = reactionTypeFilled;
        this.reactionMemberMe = "";
        this.nbReactions = {};
        this.isReacted = false;
        this.orderOriginal = function (a, b) {
            return 0;
        };
    }
    PostComponent.prototype.ngOnInit = function () {
        this.getNbDeletedComments();
        this.getComments();
        this.setObservers();
        this.prepareCommentForm();
        this.getReactions();
    };
    PostComponent.prototype.setObservers = function () {
        this._observers.push(this.setCreatedCommentObserver());
        this._observers.push(this.setUpdatedCommentObserver());
        this._observers.push(this.setCreatedReactionObserver());
        this._observers.push(this.setUpdatedReactionObserver());
        this._observers.push(this.setRemovedReactionObserver());
    };
    PostComponent.prototype.setReactionMemberMe = function () {
        for (var i = 0; i < this.reactions.length; i++) {
            var reaction = this.reactions[i];
            if (reaction.member.getIRI() == this.memberMe.getIRI()) {
                this.reactionMemberMe = reaction.type;
                break;
            }
        }
    };
    PostComponent.prototype.getClassReactionMemberMe = function () {
        return this.reactionFilled[this.reactionMemberMe] ? this.reactionFilled[this.reactionMemberMe] : "far fa-thumbs-up";
    };
    PostComponent.prototype.getComments = function () {
        var _this = this;
        this.postService.getPostComments(this.post.getId(), { page: this._commentsPage }).subscribe(function (comments) {
            // check comments exists
            comments = comments.filter(function (comment) {
                return !_this.newComments.map(function (newComment) {
                    return newComment.getId();
                }).includes(comment.getId());
            });
            (comments.length > 0) ? _this._commentsPage++ : _this._commentsPage = 0;
            _this.loadedComments = _this.loadedComments.concat(comments);
        });
    };
    PostComponent.prototype.getReactions = function () {
        var _this = this;
        this.postService.getPostReactions(this.post.getId()).subscribe(function (reactions) {
            reactions = reactions.filter(function (reaction) {
                return !_this.newReactions.map(function (newReaction) {
                    return newReaction.getId();
                }).includes(reaction.getId());
            });
            _this.reactions = _this.reactions.concat(reactions);
            _this.getNbReactionsByType();
            _this.setReactionMemberMe();
        });
    };
    PostComponent.prototype.setCreatedCommentObserver = function () {
        var _this = this;
        return this.commentService.onCreatedComment()
            .subscribe(function (comment) {
            if (!comment) {
                return;
            }
            if (_this.post.getIRI() == comment.post.getIRI()) {
                _this.newComments.push(comment);
                if (_this.memberMe.getId() != comment.member.getId()) {
                    _this.toastrService.success(comment.member.user.firstName + " " + comment.member.user.lastName + " vient de commenter un post", 'Notification');
                }
            }
        });
    };
    PostComponent.prototype.setUpdatedCommentObserver = function () {
        var _this = this;
        return this.commentService.onUpdatedComment()
            .subscribe(function (comment) {
            if (!comment) {
                return;
            }
            if (_this.post.getIRI() == comment.post.getIRI()) {
                _this.loadedComments = _this.mapComments(_this.loadedComments, comment);
                _this.newComments = _this.mapComments(_this.newComments, comment);
            }
        });
    };
    PostComponent.prototype.setCreatedReactionObserver = function () {
        var _this = this;
        return this.reactionService.onCreatedReaction()
            .subscribe(function (reaction) {
            if (!reaction) {
                return;
            }
            if (reaction.post != null) {
                if (_this.post.getIRI() == reaction.post.getIRI()) {
                    _this.newReactions.push(reaction);
                    _this.getNbReactionsByType();
                    if (_this.memberMe.getId() != reaction.member.getId()) {
                        _this.toastrService.success(reaction.member.user.firstName + " " + reaction.member.user.lastName + " vient de r\u00E9agir \u00E0 un post", 'Notification');
                    }
                }
            }
        });
    };
    PostComponent.prototype.setUpdatedReactionObserver = function () {
        var _this = this;
        return this.reactionService.onUpdatedReaction()
            .subscribe(function (reaction) {
            if (!reaction) {
                return;
            }
            if (reaction.post != null) {
                if (_this.post.getIRI() == reaction.post.getIRI()) {
                    _this.reactions = _this.mapReactions(_this.reactions, reaction);
                    _this.newReactions = _this.mapReactions(_this.newReactions, reaction);
                    _this.getNbReactionsByType();
                }
            }
        });
    };
    PostComponent.prototype.setRemovedReactionObserver = function () {
        var _this = this;
        return this.reactionService.onRemovedReaction()
            .subscribe(function (reaction) {
            if (!reaction) {
                return;
            }
            _this.newReactions = _this.filterReactions(_this.newReactions, reaction);
            _this.reactions = _this.filterReactions(_this.reactions, reaction);
            _this.getNbReactionsByType();
        });
    };
    PostComponent.prototype.getNbDeletedComments = function () {
        var _this = this;
        this.postService.getPostDeletedComments(this.post.getId()).subscribe(function (comments) {
            _this.nbDeletedComments = comments.length;
        });
    };
    PostComponent.prototype.getNbComments = function () {
        var currentNbComments = 0;
        for (var i = 0; i < this.loadedComments.length; i++) {
            if (!this.loadedComments[i].deletedAt) {
                currentNbComments++;
            }
        }
        for (var i = 0; i < this.newComments.length; i++) {
            if (!this.newComments[i].deletedAt) {
                currentNbComments++;
            }
        }
        return (currentNbComments > this.post.comments.length - this.nbDeletedComments) ? currentNbComments : this.post.comments.length - this.nbDeletedComments;
    };
    PostComponent.prototype.getNbReactionsByType = function () {
        var _a;
        this.nbReactions = (_a = {}, _a[ReactionType.LIKE] = 0, _a[ReactionType.LOVE] = 0, _a[ReactionType.QUESTION] = 0, _a);
        for (var i = 0; i < this.reactions.length; i++) {
            this.nbReactions[this.reactions[i].type] += 1;
        }
        for (var j = 0; j < this.newReactions.length; j++) {
            this.nbReactions[this.newReactions[j].type] += 1;
        }
        this.isReacted = this.reactions.length > 0 || this.newReactions.length > 0;
    };
    PostComponent.prototype.prepareCommentForm = function () {
        this.commentForm = new FormGroup({
            message: new FormControl('', Validators.required)
        });
    };
    PostComponent.prototype.setNewComment = function () {
        this.newComment.message = this.commentForm.get('message').value;
        this.newComment.post = this.post.getIRI();
    };
    PostComponent.prototype.onCommentFormSubmit = function () {
        var _this = this;
        this.commentForm['submitted'] = true;
        if (this.commentForm.valid) {
            this.setNewComment();
            this.loadingService.showLoading();
            this.commentService.createComment(this.newComment).pipe(finalize(function () { return _this.loadingService.dismissLoading(); })).subscribe(function (comment) {
                _this.commentForm.reset();
                _this.commentForm['submitted'] = false;
                _this.alertService.closeAlert();
            }, function (error) {
                var errorMessage = error.errors ? error.errors : ["Impossible de poster un commentaire pour l'instant, veuillez réessayer ultérieurement, merci"];
                _this.alertService.showAlert(errorMessage, 'danger');
                console.log(error);
            });
        }
    };
    PostComponent.prototype.onLike = function () {
        this.react(ReactionType.LIKE);
    };
    PostComponent.prototype.onLove = function () {
        this.react(ReactionType.LOVE);
    };
    PostComponent.prototype.onQuestion = function () {
        this.react(ReactionType.QUESTION);
    };
    PostComponent.prototype.react = function (reactionType) {
        var _this = this;
        var alreadyReacted = false;
        var currentReaction = {};
        for (var i = 0; i < this.reactions.length; i++) {
            if (this.reactions[i].member.getIRI() == this.memberMe.getIRI()) {
                currentReaction = this.reactions[i];
                alreadyReacted = true;
                break;
            }
        }
        for (var j = 0; j < this.newReactions.length; j++) {
            if (this.newReactions[j].member.getIRI() == this.memberMe.getIRI()) {
                currentReaction = this.newReactions[j];
                alreadyReacted = true;
                break;
            }
        }
        if (alreadyReacted) {
            if (currentReaction.type != reactionType) {
                currentReaction.type = reactionType;
                currentReaction.post = this.post.getIRI();
                this.reactionService.updateReaction(currentReaction.token, currentReaction)
                    .subscribe(function (reaction) {
                    _this.reactionMemberMe = reactionType;
                });
            }
            else {
                this.reactionService.deleteReaction(currentReaction, currentReaction.token)
                    .subscribe(function (reaction) {
                    _this.reactionMemberMe = "";
                });
                this.reactionService.patchReaction(currentReaction).subscribe(function (reaction) {
                });
            }
        }
        else {
            this.newReaction.post = this.post.getIRI();
            this.newReaction.type = reactionType;
            this.reactionService.createReaction(this.newReaction)
                .subscribe(function (reaction) {
                _this.reactionMemberMe = reactionType;
            });
        }
    };
    PostComponent.prototype.filterReactions = function (reactionsTab, deletedReaction) {
        for (var i = 0; i < reactionsTab.length; i++) {
            if (deletedReaction && reactionsTab[i].getIRI() == deletedReaction.getIRI()) {
                reactionsTab.splice(i, 1);
            }
        }
        return reactionsTab;
    };
    PostComponent.prototype.mapReactions = function (reactionsTab, currentReaction) {
        for (var i = 0; i < reactionsTab.length; i++) {
            if (reactionsTab[i].getIRI() == currentReaction.getIRI()) {
                reactionsTab[i] = currentReaction;
            }
        }
        return reactionsTab;
    };
    PostComponent.prototype.mapComments = function (commentsTab, currentComment) {
        for (var i = 0; i < commentsTab.length; i++) {
            if (commentsTab[i].getIRI() == currentComment.getIRI()) {
                commentsTab[i] = currentComment;
            }
        }
        return commentsTab;
    };
    PostComponent.prototype.openReactionModal = function () {
        this.modalReaction = this.modalService.show(ModalReactionsComponent);
        this.modalReaction.content.nbReactions = this.nbReactions;
        this.modalReaction.content.reactions = this.reactions.concat(this.newReactions);
    };
    PostComponent.prototype.onEdit = function () {
        var initialState = {
            post: this.post
        };
        this.modalEditPost = this.modalService.show(ModalEditPostComponent, { initialState: initialState });
    };
    PostComponent.prototype.setDeletedPost = function () {
        var input = new FormData();
        input.append('_id', this.post.getId().toString());
        input.append('message', this.post.message);
        input.append('deletedAt', this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'));
        input.append('circle', this.post.circle.getIRI());
        return input;
    };
    PostComponent.prototype.onDelete = function () {
        var _this = this;
        this.modalConfirmation = this.modalService.show(ModalConfirmationComponent);
        this.modalConfirmation.content.objectToRemove = "supprimer ce post";
        var _combine = combineLatest(this.modalService.onHide).subscribe(function () { return _this.changeDetection.markForCheck(); });
        this.subscriptionsModal.push(this.modalService.onHide.subscribe(function (reason) {
            if (_this.modalConfirmation.content && _this.modalConfirmation.content.confirmation) {
                var deletedPost = _this.setDeletedPost();
                _this.loadingService.showLoading();
                _this.postService.updatePost(deletedPost).pipe(finalize(function () { return _this.loadingService.dismissLoading(); })).subscribe(function (post) {
                    if (_this.post.getIRI() == post.getIRI()) {
                        _this.alertService.closeAlert();
                        _this.toastrService.success('Votre post a bien été supprimé', 'Confirmation');
                    }
                }, function (error) {
                    var errorMessage = error.errors ? error.errors : ["Impossible de supprimer un message pour l'instant, veuillez réessayer ultérieurement, merci"];
                    _this.alertService.showAlert(errorMessage, 'danger');
                    console.log(error);
                });
            }
            _this.unsubscribe();
        }));
        this.subscriptionsModal.push(_combine);
    };
    PostComponent.prototype.unsubscribe = function () {
        this.subscriptionsModal.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptionsModal = [];
    };
    PostComponent.prototype.ngOnDestroy = function () {
        this._observers.forEach(function (observer, index) {
            observer.unsubscribe();
        });
    };
    return PostComponent;
}());
export { PostComponent };
