import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var AnonymousGuard = /** @class */ (function () {
    function AnonymousGuard(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    AnonymousGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.auth.isAuthenticated()
            .pipe(map(function (authenticated) {
            if (!authenticated) {
                return true;
            }
            else {
                _this.router.navigate(['']);
                return false;
            }
        }));
    };
    AnonymousGuard.prototype.canActivateChild = function (next, state) {
        return true;
    };
    AnonymousGuard.prototype.canLoad = function (route, segments) {
        return true;
    };
    AnonymousGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnonymousGuard_Factory() { return new AnonymousGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AnonymousGuard, providedIn: "root" });
    return AnonymousGuard;
}());
export { AnonymousGuard };
