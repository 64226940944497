import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reaction } from '../models/reaction.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
var ReactionService = /** @class */ (function () {
    function ReactionService(apiService, feathers) {
        this.apiService = apiService;
        this.feathers = feathers;
        this._fsServiceName = "reactions";
        this._createdReaction = new BehaviorSubject(null);
        this._updatedReaction = new BehaviorSubject(null);
        this._removedReaction = new BehaviorSubject(null);
        this.setCreatedReactionEvent();
        this.setUpdatedReactionEvent();
        this.setRemovedReactionEvent();
    }
    ReactionService.prototype.onCreatedReaction = function () {
        return this._createdReaction;
    };
    ReactionService.prototype.onUpdatedReaction = function () {
        return this._updatedReaction;
    };
    ReactionService.prototype.onRemovedReaction = function () {
        return this._removedReaction;
    };
    ReactionService.prototype.createReaction = function (data, params) {
        if (params === void 0) { params = {}; }
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (reaction) { return new Reaction().deserialize(reaction); }));
    };
    ReactionService.prototype.updateReaction = function (id, data, params) {
        if (params === void 0) { params = {}; }
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(function (reaction) { return new Reaction().deserialize(reaction); }));
    };
    ReactionService.prototype.deleteReaction = function (data, id) {
        return this.apiService.delete(this._fsServiceName, id).pipe(map(function (reaction) { return new Reaction().deserialize(reaction); }));
    };
    ReactionService.prototype.patchReaction = function (data) {
        return this.apiService.patch(this._fsServiceName, data);
    };
    ReactionService.prototype.setCreatedReactionEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('created', function (reaction) {
            _this._createdReaction.next(new Reaction().deserialize(reaction));
            _this._createdReaction.next(null);
        });
    };
    ReactionService.prototype.setUpdatedReactionEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('updated', function (reaction) {
            _this._updatedReaction.next(new Reaction().deserialize(reaction));
            _this._updatedReaction.next(null);
        });
    };
    ReactionService.prototype.setRemovedReactionEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('patched', function (reaction) {
            _this._removedReaction.next(new Reaction().deserialize(reaction));
            _this._removedReaction.next(null);
        });
    };
    ReactionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReactionService_Factory() { return new ReactionService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService)); }, token: ReactionService, providedIn: "root" });
    return ReactionService;
}());
export { ReactionService };
