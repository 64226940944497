var _a, _b;
export var ReactionType;
(function (ReactionType) {
    ReactionType["LIKE"] = "reaction_type_like";
    ReactionType["LOVE"] = "reaction_type_love";
    ReactionType["QUESTION"] = "reaction_type_question";
})(ReactionType || (ReactionType = {}));
export var reactionTypeOutlined = (_a = {},
    _a[ReactionType.LIKE] = "far fa-thumbs-up",
    _a[ReactionType.LOVE] = "far fa-heart",
    _a[ReactionType.QUESTION] = "fas fa-question",
    _a);
export var reactionTypeFilled = (_b = {},
    _b[ReactionType.LIKE] = "fas fa-thumbs-up",
    _b[ReactionType.LOVE] = "fas fa-heart",
    _b[ReactionType.QUESTION] = "fas fa-question",
    _b);
