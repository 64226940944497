import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import { environment } from '../../../environments/environment';
import { CookieStorage } from 'cookie-storage';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var FeathersService = /** @class */ (function () {
    function FeathersService() {
        var _this = this;
        this._updatedOnlineUsers = new BehaviorSubject(null);
        this._updatedOfflineUsers = new BehaviorSubject(null);
        var socket = io(environment.fsAPI.url, {
            transports: ['websocket'],
            forceNew: true,
            pingInterval: 2000,
            pingTimeout: 50000
        });
        this.client = feathers();
        this.client.configure(socketio(socket, {
            timeout: 30000
        }));
        this.client.configure(auth({
            cookie: 'at',
            storageKey: 'at',
            storage: new CookieStorage()
        }));
        socket.on('online', function (user) {
            _this._updatedOnlineUsers.next(user);
        });
        socket.on('offline', function (user) {
            _this._updatedOfflineUsers.next(user);
        });
    }
    FeathersService.prototype.onUpdatedAuthenticated = function () {
        return this._updatedOnlineUsers.asObservable();
    };
    FeathersService.prototype.onUpdatedDisconnected = function () {
        return this._updatedOfflineUsers.asObservable();
    };
    FeathersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeathersService_Factory() { return new FeathersService(); }, token: FeathersService, providedIn: "root" });
    return FeathersService;
}());
export { FeathersService };
