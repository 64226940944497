import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var LoadingService = /** @class */ (function () {
    function LoadingService() {
        this._loading = new BehaviorSubject(false);
    }
    LoadingService.prototype.isLoading = function () {
        return this._loading.asObservable();
    };
    LoadingService.prototype.showLoading = function () {
        this._loading.next(true);
    };
    LoadingService.prototype.dismissLoading = function () {
        this._loading.next(false);
    };
    LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}());
export { LoadingService };
