import { map } from 'rxjs/operators';
import { Invitation } from '../models/invitation.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var InvitationService = /** @class */ (function () {
    function InvitationService(apiService) {
        this.apiService = apiService;
        this._fsServiceName = "invitations";
        this._validationPath = "/authentification/invitation";
    }
    InvitationService.prototype.getValidationPath = function () {
        return window.location.origin + this._validationPath;
    };
    InvitationService.prototype.createInvitation = function (data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (invitation) { return new Invitation().deserialize(invitation); }));
    };
    InvitationService.prototype.acceptInvitation = function (token, data) {
        var params = {
            query: {
                operationPath: '/accept'
            }
        };
        return this.apiService.put(this._fsServiceName, token, data, params).pipe(map(function (invitation) { return new Invitation().deserialize(invitation); }));
    };
    InvitationService.prototype.getInvitation = function (token) {
        return this.apiService.get(this._fsServiceName, token).pipe(map(function (invitation) { return new Invitation().deserialize(invitation); }));
    };
    InvitationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationService_Factory() { return new InvitationService(i0.ɵɵinject(i1.ApiService)); }, token: InvitationService, providedIn: "root" });
    return InvitationService;
}());
export { InvitationService };
