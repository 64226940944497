import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/user.service";
import * as i2 from "@angular/router";
var AccountGuard = /** @class */ (function () {
    function AccountGuard(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    AccountGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (!next.params.token) {
            return of(false);
        }
        return this.userService.getUser(next.params.token).pipe(switchMap(function (response) { return of(true); }), catchError(function (error) {
            _this.router.navigate(['/']);
            return of(false);
        }));
    };
    AccountGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountGuard_Factory() { return new AccountGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router)); }, token: AccountGuard, providedIn: "root" });
    return AccountGuard;
}());
export { AccountGuard };
