import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AlertService = /** @class */ (function () {
    function AlertService() {
        this._alert = new BehaviorSubject(null);
    }
    AlertService.prototype.alert = function () {
        return this._alert.asObservable();
    };
    AlertService.prototype.showAlert = function (messages, type) {
        if (type === void 0) { type = "success"; }
        this._alert.next({
            messages: messages,
            type: type
        });
    };
    AlertService.prototype.closeAlert = function () {
        this._alert.next(null);
    };
    AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
