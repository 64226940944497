import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CircleComponent } from './circle/circle.component';
import { PostsComponent } from './circle/posts/posts.component';
import { VisioComponent } from './circle/visio/visio.component';
import { SettingsComponent } from './circle/settings/settings.component';
import { PostDetailsComponent } from './circle/post-details/post-details.component';
import { AppointmentComponent } from './circle/appointment/appointment.component';
import { CircleGuard } from '../core/guards/circle.guard';
var ɵ0 = {
    isFullscreen: true
}, ɵ1 = {
    isFullscreen: true
}, ɵ2 = {
    isFullscreen: true
}, ɵ3 = {
    isFullscreen: true
}, ɵ4 = {
    isFullscreen: true,
    adminAccess: true
};
var routes = [
    {
        path: ":token",
        component: CircleComponent,
        canActivate: [CircleGuard],
        canActivateChild: [CircleGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                component: PostsComponent,
                pathMatch: 'full',
                data: ɵ0
            },
            {
                path: 'posts/:id',
                component: PostDetailsComponent,
                data: ɵ1
            },
            {
                path: 'rendez-vous/:id',
                component: AppointmentComponent,
                data: ɵ2
            },
            // {
            //   path: 'medias',
            //   component: MediasComponent
            // },
            {
                path: 'visio',
                component: VisioComponent,
                data: ɵ3
            },
            {
                path: 'parametres',
                component: SettingsComponent,
                runGuardsAndResolvers: 'always',
                data: ɵ4
            },
            // {
            //   path: 'agenda',
            //   component: EventsComponent
            // },
            {
                path: '**',
                redirectTo: '',
            }
        ],
    },
    {
        path: "**",
        component: DashboardComponent,
        runGuardsAndResolvers: 'always',
    },
];
var CirclesRoutingModule = /** @class */ (function () {
    function CirclesRoutingModule() {
    }
    return CirclesRoutingModule;
}());
export { CirclesRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
