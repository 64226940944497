import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./me.service";
var AuthService = /** @class */ (function () {
    function AuthService(feathersService, meService) {
        this.feathersService = feathersService;
        this.meService = meService;
        this._strategy = 'api';
        this._authentication = new BehaviorSubject(false);
    }
    AuthService.prototype.isAuthenticated = function () {
        return this._authentication.asObservable();
    };
    AuthService.prototype.updateAuthentication = function (authenticated) {
        this._authentication.next(authenticated);
    };
    AuthService.prototype.authenticateAnonymous = function () {
        var _this = this;
        var data = {
            strategy: this._strategy,
            code: 'client_credentials',
            clientId: environment.fsAPI.clientId,
            clientSecret: environment.fsAPI.clientSecret
        };
        return new Promise(function (resolve, reject) {
            _this.feathersService.client.authenticate(data).then(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    AuthService.prototype.authenticate = function (data) {
        var _this = this;
        if (data === void 0) { data = {}; }
        data = Object.assign({
            strategy: this._strategy,
            clientId: environment.fsAPI.clientId,
            clientSecret: environment.fsAPI.clientSecret,
            code: !data.accessToken ? 'password' : 'refresh_token'
        }, data);
        return new Promise(function (resolve, reject) {
            _this.feathersService.client.authenticate(data).then(function (res) {
                _this.meService.storeMe(new User().deserialize(res.user));
                _this._authentication.next(true);
                resolve(res);
            }, function (err) {
                _this._authentication.next(false);
                reject(err);
            });
        });
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.feathersService.client.logout().then(function (res) {
                _this.meService.deleteMe();
                _this._authentication.next(false);
                resolve();
            }, function (err) { return reject(err); });
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.MeService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
