import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CookieStorage } from 'cookie-storage';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./auth.service";
var ApiService = /** @class */ (function () {
    function ApiService(feathersService, authService) {
        this.feathersService = feathersService;
        this.authService = authService;
        this._storageKey = 'at';
        this._cookieStorage = new CookieStorage();
    }
    ApiService.prototype.getAll = function (path, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return from(this.feathersService.client.service(path).find(params)).pipe(catchError(function (error) {
            if (401 == error.status) {
                var accessToken = _this._cookieStorage.getItem(_this._storageKey);
                return from(_this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(function (response) { return _this.getAll(path, params); }));
            }
            return throwError(error);
        }));
    };
    ApiService.prototype.get = function (path, id, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return from(this.feathersService.client.service(path).get(id, params)).pipe(catchError(function (error) {
            if (401 == error.status) {
                var accessToken = _this._cookieStorage.getItem(_this._storageKey);
                return from(_this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(function (response) { return _this.get(path, id, params); }));
            }
            return throwError(error);
        }));
    };
    ApiService.prototype.post = function (path, data, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return from(this.feathersService.client.service(path).create(data, params)).pipe(catchError(function (error) {
            if (401 == error.status) {
                var accessToken = _this._cookieStorage.getItem(_this._storageKey);
                return from(_this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(function (response) { return _this.post(path, data, params); }));
            }
            return throwError(error);
        }));
    };
    ApiService.prototype.put = function (path, id, data, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return from(this.feathersService.client.service(path).update(id, data, params)).pipe(catchError(function (error) {
            if (401 == error.status) {
                var accessToken = _this._cookieStorage.getItem(_this._storageKey);
                return from(_this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(function (response) { return _this.put(path, id, data, params); }));
            }
            return throwError(error);
        }));
    };
    ApiService.prototype.delete = function (path, id) {
        var _this = this;
        return from(this.feathersService.client.service(path).remove(id)).pipe(catchError(function (error) {
            if (401 == error.status) {
                var accessToken = _this._cookieStorage.getItem(_this._storageKey);
                return from(_this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(function (response) { return _this.delete(path, id); }));
            }
            return throwError(error);
        }));
    };
    ApiService.prototype.patch = function (path, data, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return from(this.feathersService.client.service(path).patch("", data, params)).pipe(catchError(function (error) {
            if (401 == error.status) {
                var accessToken = _this._cookieStorage.getItem(_this._storageKey);
                return from(_this.authService.authenticate({
                    accessToken: accessToken
                })).pipe(switchMap(function (response) { return _this.patch(path, data); }));
            }
            return throwError(error);
        }));
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.AuthService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
