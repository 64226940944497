import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./feathers.service";
import * as i3 from "@angular/common/http";
var MediaService = /** @class */ (function () {
    function MediaService(apiService, feathersService, http) {
        this.apiService = apiService;
        this.feathersService = feathersService;
        this.http = http;
        this._fsServiceName = "media";
    }
    MediaService.prototype.downloadFile = function (id, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/download-file'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.get(this._fsServiceName, id, defaultParams);
    };
    MediaService.prototype.deleteMedia = function (id) {
        return this.apiService.delete(this._fsServiceName, id);
    };
    MediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaService_Factory() { return new MediaService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.FeathersService), i0.ɵɵinject(i3.HttpClient)); }, token: MediaService, providedIn: "root" });
    return MediaService;
}());
export { MediaService };
