import { CookieStorage } from 'cookie-storage';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./me.service";
var AppInitService = /** @class */ (function () {
    function AppInitService(auth, me) {
        this.auth = auth;
        this.me = me;
        this._storageKey = 'at';
        this._cookieStorage = new CookieStorage();
    }
    AppInitService.prototype.getAuthentication = function () {
        var _this = this;
        var accessToken = this._cookieStorage.getItem(this._storageKey);
        var url = window.location.href;
        if (accessToken && url.lastIndexOf('telecharger-donnees') == -1) {
            return new Promise(function (resolve, reject) {
                _this.auth.authenticate({
                    accessToken: accessToken
                }).catch(function (e) {
                    _this._cookieStorage.removeItem(_this._storageKey);
                    _this.me.deleteMe();
                }).finally(function () { return resolve(); });
            });
        }
        else {
            this.me.deleteMe();
            return Promise.resolve();
        }
    };
    AppInitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInitService_Factory() { return new AppInitService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.MeService)); }, token: AppInitService, providedIn: "root" });
    return AppInitService;
}());
export { AppInitService };
