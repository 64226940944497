import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var HeaderService = /** @class */ (function () {
    function HeaderService() {
        this._menuClosure = new BehaviorSubject(false);
        this._headerSwipe = new BehaviorSubject(false);
    }
    HeaderService.prototype.closeMenu = function () {
        this._menuClosure.next(false);
    };
    HeaderService.prototype.closedMenu = function () {
        return this._menuClosure.asObservable();
    };
    HeaderService.prototype.swipeHeader = function (swiped) {
        this._headerSwipe.next(swiped);
    };
    HeaderService.prototype.swipedHeader = function () {
        return this._headerSwipe.asObservable();
    };
    HeaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(); }, token: HeaderService, providedIn: "root" });
    return HeaderService;
}());
export { HeaderService };
