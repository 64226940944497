import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/password-request.service";
import * as i2 from "@angular/router";
var PasswordRequestGuard = /** @class */ (function () {
    function PasswordRequestGuard(passwordRequestService, router) {
        this.passwordRequestService = passwordRequestService;
        this.router = router;
    }
    PasswordRequestGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (!next.params.token) {
            return of(false);
        }
        return this.passwordRequestService.getPasswordRequest(next.params.token).pipe(switchMap(function (response) { return of(true); }), catchError(function (error) {
            _this.router.navigate(['']);
            return of(false);
        }));
    };
    PasswordRequestGuard.prototype.canActivateChild = function (next, state) {
        return true;
    };
    PasswordRequestGuard.prototype.canLoad = function (route, segments) {
        return true;
    };
    PasswordRequestGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordRequestGuard_Factory() { return new PasswordRequestGuard(i0.ɵɵinject(i1.PasswordRequestService), i0.ɵɵinject(i2.Router)); }, token: PasswordRequestGuard, providedIn: "root" });
    return PasswordRequestGuard;
}());
export { PasswordRequestGuard };
