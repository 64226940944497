import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { SubscriptionModel } from '../../core/models/subscription.model';
var SubscriptionResultComponent = /** @class */ (function () {
    function SubscriptionResultComponent(route, router, subscriptionService) {
        this.route = route;
        this.router = router;
        this.subscriptionService = subscriptionService;
        this.isAccepted = false;
    }
    SubscriptionResultComponent.prototype.ngOnInit = function () {
        this.updateSubscription();
    };
    SubscriptionResultComponent.prototype.updateSubscription = function () {
        var parameters = this.route.snapshot.queryParams;
        if (parameters['signature']) {
            if (this.verifySignature()) {
                if (SubscriptionModel.STATUS_OK.indexOf(parameters['vads_trans_status']) > -1) {
                    this.isAccepted = true;
                }
                var subscription = {};
                subscription.alias = parameters['vads_identifier'];
                subscription.frequency = parameters['vads_sub_desc'].includes('MONTHLY') ? (parameters['vads_sub_desc'].includes('INTERVAL=3') ? 3 : 1) : 12;
                subscription.price = parameters['vads_sub_amount'] / 100;
                subscription.status = parameters['vads_trans_status'];
                var effectDate = parameters['vads_sub_effect_date'];
                effectDate = [effectDate.slice(0, 4), '-', effectDate.slice(4)].join('');
                effectDate = [effectDate.slice(0, 7), '-', effectDate.slice(7)].join('');
                subscription.effectDate = effectDate;
                this.subscriptionService.updateSubscription(parameters['vads_ext_info_subscription_id'], subscription);
            }
        }
    };
    SubscriptionResultComponent.prototype.verifySignature = function () {
        var e_1, _a;
        var parameters = this.route.snapshot.queryParams;
        var signatureContent = "";
        var keySogecommerce = environment.sogecommerce.key;
        var ordered = Object.entries(parameters).sort();
        try {
            for (var ordered_1 = tslib_1.__values(ordered), ordered_1_1 = ordered_1.next(); !ordered_1_1.done; ordered_1_1 = ordered_1.next()) {
                var _b = tslib_1.__read(ordered_1_1.value, 2), key = _b[0], value = _b[1];
                if (key.substring(0, 5) == 'vads_') {
                    signatureContent += value + "+";
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ordered_1_1 && !ordered_1_1.done && (_a = ordered_1.return)) _a.call(ordered_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        signatureContent += keySogecommerce;
        var hash = HmacSHA256(signatureContent, keySogecommerce);
        return Base64.stringify(hash) == parameters['signature'];
    };
    SubscriptionResultComponent.prototype.refreshIframe = function () {
        this.router.navigateByUrl('profil/paiement');
    };
    return SubscriptionResultComponent;
}());
export { SubscriptionResultComponent };
