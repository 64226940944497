import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
var SubscriptionUpdateResultComponent = /** @class */ (function () {
    function SubscriptionUpdateResultComponent(route, router) {
        this.route = route;
        this.router = router;
        this.isAccepted = false;
    }
    SubscriptionUpdateResultComponent.prototype.ngOnInit = function () {
        this.getResult();
    };
    SubscriptionUpdateResultComponent.prototype.getResult = function () {
        var parameters = this.route.snapshot.queryParams;
        if (parameters['signature']) {
            if (this.verifySignature()) {
                if ("ACCEPTED".indexOf(parameters['vads_trans_status']) > -1) {
                    this.isAccepted = true;
                }
            }
        }
    };
    SubscriptionUpdateResultComponent.prototype.verifySignature = function () {
        var e_1, _a;
        var parameters = this.route.snapshot.queryParams;
        var signatureContent = "";
        var keySogecommerce = environment.sogecommerce.key;
        var ordered = Object.entries(parameters).sort();
        try {
            for (var ordered_1 = tslib_1.__values(ordered), ordered_1_1 = ordered_1.next(); !ordered_1_1.done; ordered_1_1 = ordered_1.next()) {
                var _b = tslib_1.__read(ordered_1_1.value, 2), key = _b[0], value = _b[1];
                if (key.substring(0, 5) == 'vads_') {
                    signatureContent += value + "+";
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ordered_1_1 && !ordered_1_1.done && (_a = ordered_1.return)) _a.call(ordered_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        signatureContent += keySogecommerce;
        var hash = HmacSHA256(signatureContent, keySogecommerce);
        return Base64.stringify(hash) == parameters['signature'];
    };
    SubscriptionUpdateResultComponent.prototype.refreshIframe = function () {
        this.router.navigateByUrl('/profil/abonnement/mettre-a-jour/iframe');
    };
    return SubscriptionUpdateResultComponent;
}());
export { SubscriptionUpdateResultComponent };
