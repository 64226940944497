import { map } from 'rxjs/operators';
import { SubscriptionModel } from '../models/subscription.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService(apiService) {
        this.apiService = apiService;
        this._fsServiceName = "subscriptions";
    }
    SubscriptionService.prototype.createSubscription = function (data, params) {
        if (params === void 0) { params = {}; }
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (subscription) { return new SubscriptionModel().deserialize(subscription); }));
    };
    SubscriptionService.prototype.updateSubscription = function (id, data, params) {
        if (params === void 0) { params = {}; }
        return this.apiService.put(this._fsServiceName, id, data, params).pipe(map(function (subscription) { return new SubscriptionModel().deserialize(subscription); }));
    };
    SubscriptionService.prototype.updateStorageSubscription = function (id, data, params) {
        if (params === void 0) { params = {}; }
        var defaultParams = {
            query: {
                operationPath: '/increase-storage'
            }
        };
        Object.assign(defaultParams.query, params);
        return this.apiService.put(this._fsServiceName, id, data, defaultParams).pipe(map(function (subscription) { return new SubscriptionModel().deserialize(subscription); }));
    };
    SubscriptionService.prototype.deleteSubscription = function (id) {
        return this.apiService.delete(this._fsServiceName, id);
    };
    SubscriptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.ɵɵinject(i1.ApiService)); }, token: SubscriptionService, providedIn: "root" });
    return SubscriptionService;
}());
export { SubscriptionService };
