import { Event } from '../models/event.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
var EventService = /** @class */ (function () {
    function EventService(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "events";
        this._createdEvent = new BehaviorSubject(null);
        this._deletedEvent = new BehaviorSubject(null);
        this.setCreatedEventEvent();
        this.setDeletedEventEvent();
    }
    EventService.prototype.onCreatedEvent = function () {
        return this._createdEvent;
    };
    EventService.prototype.createEvent = function (data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (event) { return new Event().deserialize(event); }));
    };
    EventService.prototype.setCreatedEventEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('created', function (event) {
            _this._createdEvent.next(new Event().deserialize(event));
            _this._createdEvent.next(null);
        });
    };
    EventService.prototype.onDeletedEvent = function () {
        return this._deletedEvent;
    };
    EventService.prototype.deleteEvent = function (id) {
        return this.apiService.delete(this._fsServiceName, id).pipe(map(function (event) { return new Event().deserialize(event); }));
    };
    EventService.prototype.patchEvent = function (data) {
        return this.apiService.patch(this._fsServiceName, data);
    };
    EventService.prototype.setDeletedEventEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('patched', function (event) {
            _this._deletedEvent.next(new Event().deserialize(event));
            _this._deletedEvent.next(null);
        });
    };
    EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
