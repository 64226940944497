import { OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Appointment } from '../../../core/models/appointment.model';
import { Vote } from '../../../core/models/vote.model';
import { Member } from '../../../core/models/member.model';
import { ModalAddProposalComponent } from '../modal-add-proposal/modal-add-proposal.component';
var AppointmentComponent = /** @class */ (function () {
    function AppointmentComponent(appointmentService, activatedRoute, circleService, proposalService, voteService, alertService, modalService, loadingService, toastrService, formBuilder, router) {
        this.appointmentService = appointmentService;
        this.activatedRoute = activatedRoute;
        this.circleService = circleService;
        this.proposalService = proposalService;
        this.voteService = voteService;
        this.alertService = alertService;
        this.modalService = modalService;
        this.loadingService = loadingService;
        this.toastrService = toastrService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.memberMe = new Member();
        this._observers = [];
        this.isVisio = Appointment.VISIO;
        this.yes = Vote.CHOICE_YES;
        this.maybe = Vote.CHOICE_MAYBE;
        this.no = Vote.CHOICE_NO;
        this.creatorProposals = [];
        this.counterProposals = [];
        this.newCounterProposals = [];
        this.votesProposal = [];
        this.votesCounterProposal = [];
        this.newVotes = [];
        this.newVotesCounterProp = [];
        this.meHasVoted = false;
        this.meVotes = [];
        this.yesTotal = [];
        this.newYesTotal = [];
    }
    AppointmentComponent.prototype.ngOnInit = function () {
        this.getAppointment();
        this.setObservers();
        this.prepareVoteForm();
        this.prepareVoteCounterPropForm();
    };
    AppointmentComponent.prototype.setObservers = function () {
        this._observers.push(this.setCreatedProposalObserver());
        this._observers.push(this.setCreatedVoteObserver());
    };
    AppointmentComponent.prototype.setCreatedProposalObserver = function () {
        var _this = this;
        return this.proposalService.onCreatedProposal().subscribe(function (proposal) {
            if (!proposal) {
                return;
            }
            if (_this.appointment && proposal.appointment == _this.appointment.getIRI() && proposal.isCounterProposal) {
                _this.newCounterProposals.push(proposal);
                var nbCounterProp = _this.counterProposals.length + _this.newCounterProposals.length;
                for (var i = _this.counterProposals.length; i < nbCounterProp; i++) {
                    _this.proposalsArray.push(_this.formBuilder.group({
                        vote: ['']
                    }));
                }
            }
        });
    };
    AppointmentComponent.prototype.setCreatedVoteObserver = function () {
        var _this = this;
        return this.voteService.onCreatedVote().subscribe(function (vote) {
            if (!vote) {
                return;
            }
            if (_this.newVotes.filter(function (v) { return v.member.getId() == vote.member.getId() && v.proposal.getId() == vote.proposal.getId(); }).length == 0) {
                for (var i = 0; i < _this.creatorProposals.length; i++) {
                    if (_this.creatorProposals[i].getIRI() == vote.proposal.getIRI()) {
                        _this.newVotes.push(vote);
                        if (vote.choice == _this.yes) {
                            _this.yesTotal[vote.proposal.getId()]++;
                        }
                        if (vote.member.getIRI() == _this.memberMe.getIRI()) {
                            _this.meHasVoted = true;
                        }
                        break;
                    }
                }
            }
            if (_this.newVotesCounterProp.filter(function (v) { return v.member.getId() == vote.member.getId() && v.proposal.getId() == vote.proposal.getId(); }).length == 0) {
                for (var i = 0; i < _this.counterProposals.length; i++) {
                    if (_this.counterProposals[i].getIRI() == vote.proposal.getIRI()) {
                        _this.newVotesCounterProp.push(vote);
                        if (vote.choice == _this.yes) {
                            _this.yesTotal[vote.proposal.getId()]++;
                        }
                        if (vote.member.getIRI() == _this.memberMe.getIRI()) {
                            _this.meVotes.push(vote.proposal.getId());
                        }
                        break;
                    }
                }
                for (var i = 0; i < _this.newCounterProposals.length; i++) {
                    if (_this.newCounterProposals[i].getIRI() == vote.proposal.getIRI()) {
                        _this.newVotesCounterProp.push(vote);
                        if (vote.choice == _this.yes) {
                            _this.newYesTotal[vote.proposal.getId()]++;
                        }
                        if (vote.member.getIRI() == _this.memberMe.getIRI()) {
                            _this.meVotes.push(vote.proposal.getId());
                        }
                        break;
                    }
                }
            }
        });
    };
    AppointmentComponent.prototype.getMe = function (token) {
        var _this = this;
        this.circleService.getCircleMembersMe(token).subscribe(function (member) {
            _this.memberMe = member;
            _this.getProposalsAndVotes();
        });
    };
    AppointmentComponent.prototype.getProposalsAndVotes = function () {
        var _this = this;
        var _loop_1 = function () {
            var proposal = this_1.appointment.proposals[i];
            this_1.yesTotal[proposal.getId()] = 0;
            // Get proposals from the creator of the appointment
            if (this_1.appointment.member.getId() == proposal.member.getId() && !proposal.isCounterProposal) {
                this_1.creatorProposals.push(proposal);
                this_1.creatorProposals.sort(function (a, b) { return (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0); });
                this_1.proposalService.getProposalVotes(proposal.getId()).subscribe(function (votes) {
                    _this.votesProposal = _this.votesProposal.concat(votes);
                    for (var j = 0; j < votes.length; j++) {
                        if (votes[j].choice == _this.yes) {
                            _this.yesTotal[proposal.getId()]++;
                        }
                        if (votes[j].member.getId() == _this.memberMe.getId()) {
                            // If memberMe has one vote to one proposal of the creator, it is not necessary to display the form
                            _this.meHasVoted = true;
                        }
                    }
                });
            }
            else {
                this_1.counterProposals.push(proposal);
                this_1.counterProposals.sort(function (a, b) { return (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0); });
                this_1.proposalService.getProposalVotes(proposal.getId()).subscribe(function (votes) {
                    _this.votesCounterProposal = _this.votesCounterProposal.concat(votes);
                    for (var j = 0; j < votes.length; j++) {
                        if (votes[j].choice == _this.yes) {
                            _this.yesTotal[proposal.getId()]++;
                        }
                        if (votes[j].member.getId() == _this.memberMe.getId()) {
                            // If memberMe has voted for the proposal, it is not necessary to display the radio buttons
                            _this.meVotes.push(proposal.getId());
                        }
                    }
                });
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.appointment.proposals.length; i++) {
            _loop_1();
        }
        for (var i_1 = this.proposalsArray.length; i_1 < this.counterProposals.length; i_1++) {
            this.proposalsArray.push(this.formBuilder.group({
                vote: ['']
            }));
        }
    };
    AppointmentComponent.prototype.prepareVoteForm = function () {
        this.voteForm = new FormGroup({
            votePropOne: new FormControl('', Validators.required),
            votePropTwo: new FormControl('', this.creatorProposals.length > 1 ? Validators.required : null),
            votePropThree: new FormControl('', this.creatorProposals.length > 2 ? Validators.required : null)
        });
    };
    AppointmentComponent.prototype.prepareVoteCounterPropForm = function () {
        this.voteCounterPropForm = this.formBuilder.group({
            proposals: new FormArray([])
        });
    };
    AppointmentComponent.prototype.getAppointment = function () {
        var _this = this;
        return this.appointmentService.getAppointment(this.activatedRoute.snapshot.params.id).subscribe(function (appointment) {
            _this.appointment = appointment;
            _this.getMe(appointment.circle.token);
        }, function (error) {
            console.log(error.errors);
        });
    };
    AppointmentComponent.prototype.setNewVote = function (proposal, choice) {
        var newVote = {};
        newVote.proposal = proposal.getIRI();
        newVote.choice = choice;
        return newVote;
    };
    AppointmentComponent.prototype.onVoteFormSubmit = function () {
        var _this = this;
        this.voteForm['submitted'] = true;
        if (this.voteForm.valid) {
            var newVote = {};
            for (var i = 0; i < this.creatorProposals.length; i++) {
                var choice = (i == 0) ? this.votePropOne.value : ((i == 1) ? this.votePropTwo.value : this.votePropThree.value);
                newVote = this.setNewVote(this.creatorProposals[i], choice);
                this.voteService.createVote(newVote).subscribe(function (vote) {
                    _this.voteForm['submitted'] = false;
                    _this.voteForm.reset();
                }, function (error) {
                    console.log(error.errors);
                    _this.alertService.showAlert(error.errors, 'danger');
                });
            }
        }
    };
    AppointmentComponent.prototype.onVoteCounterPropFormSubmit = function () {
        var _this = this;
        this.voteCounterPropForm['submitted'] = true;
        if (this.voteCounterPropForm.valid) {
            var _loop_2 = function (i) {
                if (this_2.proposalsArray.value[i].vote != '') {
                    var newVote = {};
                    newVote.proposal = i < this_2.counterProposals.length ? this_2.counterProposals[i].getIRI() : this_2.newCounterProposals[i - this_2.counterProposals.length].getIRI();
                    newVote.choice = this_2.proposalsArray.value[i].vote;
                    this_2.voteService.createVote(newVote).subscribe(function (vote) {
                        _this.voteCounterPropForm['submitted'] = false;
                        _this.voteCounterPropForm.reset();
                        _this.proposalsArray.value[i].vote = '';
                    }, function (error) {
                        console.log(error.errors);
                        _this.alertService.showAlert(error.errors, 'danger');
                    });
                }
            };
            var this_2 = this;
            for (var i = 0; i < this.proposalsArray.length; i++) {
                _loop_2(i);
            }
        }
    };
    AppointmentComponent.prototype.onChoose = function (proposalId) {
        var _this = this;
        var updatedAppointment = {};
        this.loadingService.showLoading();
        updatedAppointment.circle = this.appointment.circle.getIRI();
        var finalProposal = this.creatorProposals.filter(function (p) { return p.getId() == proposalId; }).length > 0 ? this.creatorProposals.filter(function (p) { return p.getId() == proposalId; }) : this.counterProposals.filter(function (p) { return p.getId() == proposalId; });
        updatedAppointment.finalChoice = finalProposal[0].date;
        this.appointmentService.updateAppointment(this.appointment.getId(), updatedAppointment).pipe(finalize(function () { return _this.loadingService.dismissLoading(); })).subscribe(function (appointment) {
            _this.alertService.closeAlert();
            _this.router.navigate(['cercles', _this.appointment.circle.token]);
            _this.toastrService.success('La date finale du rendez-vous a bien été choisie', 'Confirmation');
        }, function (error) {
            console.log(error);
            _this.alertService.showAlert(error.errors, 'danger');
        });
    };
    AppointmentComponent.prototype.openAddProposalModal = function () {
        this.modalAddProposal = this.modalService.show(ModalAddProposalComponent);
        this.modalAddProposal.content.appointment = this.appointment;
    };
    Object.defineProperty(AppointmentComponent.prototype, "votePropOne", {
        get: function () { return this.voteForm.get('votePropOne'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentComponent.prototype, "votePropTwo", {
        get: function () { return this.voteForm.get('votePropTwo'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentComponent.prototype, "votePropThree", {
        get: function () { return this.voteForm.get('votePropThree'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentComponent.prototype, "dynamicControls", {
        get: function () { return this.voteCounterPropForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppointmentComponent.prototype, "proposalsArray", {
        get: function () { return this.dynamicControls.proposals; },
        enumerable: true,
        configurable: true
    });
    AppointmentComponent.prototype.ngOnDestroy = function () {
        this._observers.forEach(function (observer, index) {
            observer.unsubscribe();
        });
    };
    return AppointmentComponent;
}());
export { AppointmentComponent };
