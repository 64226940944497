import { from } from 'rxjs';
import { switchMap, map, finalize } from 'rxjs/operators';
import { PasswordRequest } from '../models/password-request.model';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./api.service";
var PasswordRequestService = /** @class */ (function () {
    function PasswordRequestService(authService, apiService) {
        this.authService = authService;
        this.apiService = apiService;
        this._fsServiceName = 'passwordRequests';
        this._resetPasswordPath = "/authentification/nouveau-mot-de-passe";
    }
    PasswordRequestService.prototype.getResetPasswordPath = function () {
        return window.location.origin + this._resetPasswordPath;
    };
    PasswordRequestService.prototype.createPasswordRequest = function (data) {
        var _this = this;
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            return _this.apiService.post(_this._fsServiceName, data);
        }), finalize(function () { return _this.authService.logout(); }));
    };
    PasswordRequestService.prototype.resetPassword = function (token, data) {
        var _this = this;
        var params = {
            query: {
                operationPath: '/reset-password'
            }
        };
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            return _this.apiService.put(_this._fsServiceName, token, data, params);
        }), map(function (passwordRequest) { return new PasswordRequest().deserialize(passwordRequest); }), finalize(function () { return _this.authService.logout(); }));
    };
    PasswordRequestService.prototype.getPasswordRequest = function (token) {
        var _this = this;
        return from(this.authService.authenticateAnonymous()).pipe(switchMap(function (response) {
            return _this.apiService.get(_this._fsServiceName, token);
        }), map(function (passwordRequest) { return new PasswordRequest().deserialize(passwordRequest); }), finalize(function () { return _this.authService.logout(); }));
    };
    PasswordRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordRequestService_Factory() { return new PasswordRequestService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.ApiService)); }, token: PasswordRequestService, providedIn: "root" });
    return PasswordRequestService;
}());
export { PasswordRequestService };
