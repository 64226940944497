import { PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
var DateFromPipe = /** @class */ (function () {
    function DateFromPipe() {
    }
    DateFromPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var today = new Date();
        var date = new Date(value);
        var diffTime = today.getTime() - date.getTime();
        diffTime = diffTime / 1000;
        var seconds = Math.floor(diffTime % 60);
        diffTime = diffTime / 60;
        var minutes = Math.floor(diffTime % 60);
        diffTime = diffTime / 60;
        var hours = Math.floor(diffTime % 24);
        var days = Math.floor(diffTime / 24);
        var dateFrom = ['Il y a'];
        if (days < 7) {
            if (days > 0) {
                dateFrom.push(days + "j");
                dateFrom.push(hours + "h");
                dateFrom.push(minutes + "min");
            }
            else if (hours > 0) {
                dateFrom.push(hours + "h");
                dateFrom.push(minutes + "min");
            }
            else if (minutes > 0) {
                dateFrom.push(minutes + "min");
            }
            else if (seconds > 0) {
                dateFrom.push(seconds + "s");
            }
            else {
                dateFrom.push("1s");
            }
            if (dateFrom.length) {
                return dateFrom.join(' ');
            }
        }
        return formatDate(date, "dd/MM/yy HH:mm", 'fr-FR');
    };
    return DateFromPipe;
}());
export { DateFromPipe };
