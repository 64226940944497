import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment } from '../models/appointment.model';
import * as i0 from "@angular/core";
import * as i1 from "./feathers.service";
import * as i2 from "./api.service";
var AppointmentService = /** @class */ (function () {
    function AppointmentService(feathers, apiService) {
        this.feathers = feathers;
        this.apiService = apiService;
        this._fsServiceName = "appointments";
        this._createdAppointment = new BehaviorSubject(null);
        this._updatedAppointment = new BehaviorSubject(null);
        this._deletedAppointment = new BehaviorSubject(null);
        this.setCreatedAppointmentEvent();
        this.setUpdatedAppointmentEvent();
        this.setDeletedAppointmentEvent();
    }
    AppointmentService.prototype.getAppointment = function (id) {
        return this.apiService.get(this._fsServiceName, id).pipe(map(function (circle) { return new Appointment().deserialize(circle); }));
    };
    AppointmentService.prototype.onCreatedAppointment = function () {
        return this._createdAppointment;
    };
    AppointmentService.prototype.createAppointment = function (data) {
        return this.apiService.post(this._fsServiceName, data).pipe(map(function (event) { return new Appointment().deserialize(event); }));
    };
    AppointmentService.prototype.setCreatedAppointmentEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('created', function (event) {
            _this._createdAppointment.next(new Appointment().deserialize(event));
        });
    };
    AppointmentService.prototype.onUpdatedAppointment = function () {
        return this._updatedAppointment;
    };
    AppointmentService.prototype.updateAppointment = function (id, data) {
        return this.apiService.put(this._fsServiceName, id, data).pipe(map(function (event) { return new Appointment().deserialize(event); }));
    };
    AppointmentService.prototype.setUpdatedAppointmentEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('updated', function (appointment) {
            _this._updatedAppointment.next(new Appointment().deserialize(appointment));
            _this._updatedAppointment.next(null);
        });
    };
    AppointmentService.prototype.onDeletedAppointment = function () {
        return this._deletedAppointment;
    };
    AppointmentService.prototype.deleteAppointment = function (id) {
        return this.apiService.delete(this._fsServiceName, id);
    };
    AppointmentService.prototype.patchAppointment = function (data) {
        return this.apiService.patch(this._fsServiceName, data);
    };
    AppointmentService.prototype.setDeletedAppointmentEvent = function () {
        var _this = this;
        this.feathers.client.service(this._fsServiceName).on('patched', function (event) {
            _this._deletedAppointment.next(new Appointment().deserialize(event));
            _this._deletedAppointment.next(null);
        });
    };
    AppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.ɵɵinject(i1.FeathersService), i0.ɵɵinject(i2.ApiService)); }, token: AppointmentService, providedIn: "root" });
    return AppointmentService;
}());
export { AppointmentService };
