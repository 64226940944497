import { PipeTransform } from '@angular/core';
var InitialsPipe = /** @class */ (function () {
    function InitialsPipe() {
    }
    InitialsPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var words = value.split(' ');
        var initials = words.map(function (word) { return word.charAt(0); });
        return initials.join();
    };
    return InitialsPipe;
}());
export { InitialsPipe };
